import { defineComponent } from 'vue';

export default defineComponent({
	name: 'STextarea',
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: ''
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		resizable: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onInput(event: Event) {
			this.$emit('update:modelValue', (event.target as HTMLTextAreaElement).value);
		}
	}
});
