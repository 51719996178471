import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrismEditor = _resolveComponent("PrismEditor")!

  return (_openBlock(), _createBlock(_component_PrismEditor, {
    class: "code-editor",
    highlight: _ctx.highlighter,
    "line-numbers": ""
  }, null, 8, ["highlight"]))
}