import App from './App.vue';
import vFocus from './directives/vFocus';
import vMask from './directives/vMask';
import { router } from './router';
import { store } from './store';
import { createApp } from 'vue';
import { setGlobalSharedComponents } from '@/shared/setGlobalSharedComponents';

const app = createApp(App)
	.use(store)
	.use(router)
	.directive(vMask.name, vMask.directive)
	.directive(vFocus.name, vFocus.directive);

setGlobalSharedComponents(app);

app.mount('#app');
