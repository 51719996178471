import { store } from '@/store';
import { createRouter, createWebHistory } from 'vue-router';
import { routesList, ERouteNames } from './routesList';

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routesList
});

router.beforeEach((to, from, next) => {
	const isRouteRequiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const isAuthorized = store.state.userStore.accessToken;

	window.scrollTo(0, 0);

	if (isAuthorized && (to.name === ERouteNames.Login || to.name === ERouteNames.SignUp)) {
		next({ name: ERouteNames.Home });

		return;
	}

	if (!isRouteRequiresAuth) {
		next();

		return;
	}

	if (!isAuthorized) {
		next({ name: ERouteNames.Login });
	} else {
		next();
	}
});
