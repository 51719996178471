import { apiClient } from '../config';
import type { ICreateSurveyRequest, IEditSurveyRequest, IGetSurveyRequest } from './request.types';
import type { ISurvey } from './response.types';

class SurveysService {
	private readonly _requestPath: string;

	public constructor() {
		this._requestPath = '/surveys';
	}

	public getSurveysList() {
		return apiClient.get<ISurvey[]>(this._requestPath);
	}

	public createSurvey({ data }: ICreateSurveyRequest) {
		return apiClient.post<ISurvey>(this._requestPath, data);
	}

	public getSurvey({ surveyId }: IGetSurveyRequest) {
		return apiClient.get<ISurvey>(`${this._requestPath}/${surveyId}`);
	}

	public editSurvey({ data }: IEditSurveyRequest) {
		return apiClient.put<ISurvey>(`${this._requestPath}/${data.id}`, data);
	}
}

export const SurveysApi = new SurveysService();
