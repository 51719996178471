import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SField',
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		leftIconClassName: {
			type: String,
			default: ''
		}
	},
	methods: {
		onInput(event: Event) {
			this.$emit('update:modelValue', (event.target as HTMLInputElement).value);
		}
	}
});
