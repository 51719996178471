/* eslint-disable class-methods-use-this */
import { apiClient } from '../config';
import type { ISignUpRequest, ILoginRequest } from './request.types';
import type { IRegisterResponse, ISignUpResponse } from './response.types';

class UserService {
	public signUp(data: ISignUpRequest) {
		return apiClient.post<ISignUpResponse>('/signup', data);
	}

	public login(data: ILoginRequest) {
		return apiClient.post<IRegisterResponse>('/login', data);
	}
}

export const UserApi = new UserService();
