import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  role: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["button", {
			'is-loading': _ctx.isProcessing,
			'is-small': _ctx.sm,
			'is-fullwidth': _ctx.fullWidth,
			[`is-${_ctx.color}`]: _ctx.color,
		}]),
    disabled: _ctx.isProcessing
  }, [
    (_ctx.buttonText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.buttonText), 1))
      : _createCommentVNode("", true),
    (_ctx.iconClass)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          role: "presentation",
          class: _normalizeClass(["icon", _ctx.iconClass])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}