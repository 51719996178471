import SButton from '../SButton/SButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SModal',
	components: {
		SButton
	},
	props: {
		title: {
			type: String,
			required: true
		},
		show: {
			type: Boolean,
			required: true
		},
		onClose: {
			type: Function,
			required: true
		},
		withoutFooter: {
			type: Boolean,
			default: false
		},
		onSubmit: {
			type: Function,
			default: undefined
		}
	}
});
