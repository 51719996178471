import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withKeys as _withKeys, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a50051ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field is-grouped is-grouped-multiline m-0" }
const _hoisted_2 = { class: "tags has-addons is-unselectable" }
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "block create-tag-container mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SCreateButton = _resolveComponent("SCreateButton")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["label", {
			'small-text': _ctx.smallLabel
		}])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.concatenatedTagsList, (tag) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tag,
          class: "control m-0"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
              class: _normalizeClass(["tag", {
						'is-link': _ctx.isLink(tag),
						'is-clickable': _ctx.isClickable(tag)
					}]),
              role: "button",
              title: _ctx.getTagTitle(tag),
              onClick: ($event: any) => (_ctx.selectTag(tag))
            }, _toDisplayString(tag), 11, _hoisted_3),
            (_ctx.isLink(tag))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  role: "button",
                  class: "tag is-delete is-clickable",
                  onClick: ($event: any) => (_ctx.removeTag(tag))
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ]),
    (_ctx.showCreateTagButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_SCreateButton, {
            "button-text": "Create new tag",
            onClick: _ctx.onCreateButtonClick
          }, null, 8, ["onClick"]), [
            [_vShow, !_ctx.showCreateTagInput]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["input is-small", {
				'is-danger': _ctx.isInvalidNewTagName
			}]),
            placeholder: "Type new tag name",
            maxlength: "15",
            form: "nosubmit",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.createTag && _ctx.createTag(...args)), ["enter"])),
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlurCreateTagInput && _ctx.onBlurCreateTagInput(...args))),
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputCreateTagInput && _ctx.onInputCreateTagInput(...args)))
          }, null, 34), [
            [_vShow, _ctx.showCreateTagInput],
            [_directive_focus]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}