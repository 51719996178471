import type { RouteRecordRaw } from 'vue-router';

const SurveysView = () => import(/* webpackChunkName: "surveysView" */ '@/views/SurveysView/SurveysView.vue');
const SurveyView = () => import(/* webpackChunkName: "surveyView" */ '@/views/SurveyView/SurveyView.vue');
const HomeView = () => import(/* webpackChunkName: "homeView" */ '@/views/HomeView/HomeView.vue');
const AuthView = () => import(/* webpackChunkName: "authView" */ '@/views/AuthView/AuthView.vue');
const NotFoundView = () => import(/* webpackChunkName: "notFoundView" */ '@/views/NotFoundView/NotFoundView.vue');

export enum ERouteNames {
	Home = 'Home',
	Login = 'Login',
	SignUp = 'SignUp',
	Surveys = 'Surveys',
	CreateSurvey = 'CreateSurvey',
	Survey = 'Survey',
	NotFound = 'NotFound'
}

const authorizedRoutesList: Array<RouteRecordRaw> = [
	{
		path: '/surveys',
		name: ERouteNames.Surveys,
		component: SurveysView,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/survey/new',
		name: ERouteNames.CreateSurvey,
		component: SurveyView,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/survey/:id',
		name: ERouteNames.Survey,
		component: SurveyView,
		meta: {
			requiresAuth: true
		}
	}
];

export const routesList: Array<RouteRecordRaw> = [
	...authorizedRoutesList,
	{
		path: '/',
		name: ERouteNames.Home,
		component: HomeView
	},
	{
		path: '/login',
		name: ERouteNames.Login,
		component: AuthView
	},
	{
		path: '/signUp',
		name: ERouteNames.SignUp,
		component: AuthView
	},
	{
		path: '/:catchAll(.*)',
		name: ERouteNames.NotFound,
		component: NotFoundView
	}
];
