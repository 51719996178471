import SButton from '../SButton/SButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SNotification',
	components: {
		SButton
	},
	props: {
		title: {
			type: String,
			required: true
		},
		onButtonClick: {
			type: Function,
			default: undefined
		},
		buttonText: {
			type: String,
			default: 'Submit'
		},
		centered: {
			type: Boolean,
			default: false
		},
		warning: {
			type: Boolean,
			default: false
		},
		danger: {
			type: Boolean,
			default: false
		}
	}
});
