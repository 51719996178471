import { UserApi } from '@/api';
import { accessTokenStorage } from '@/store/localStorage';
import { EUserMutationType } from './mutation.type';
import type { IInitialState } from './user.store';
import type { ActionObject } from 'vuex';
import type { EUserActionType, IStore } from '@/store';
import type { ISignUpRequest } from '@/api';

interface IAuth extends ISignUpRequest {
	actionType: EUserActionType.LOGIN | EUserActionType.SIGN_UP;
}

export const auth: ActionObject<IInitialState, IStore> = {
	handler: async ({ commit }, data: IAuth) => {
		if (!data.actionType) {
			return;
		}

		await UserApi[data.actionType](data).then(e => {
			commit(EUserMutationType.SET_USER, {
				user: {
					email: e.data.user.email
				}
			});
			commit(EUserMutationType.SET_ACCESS_TOKEN, e.data.accessToken);

			accessTokenStorage.setItem(e.data.accessToken);
		});
	}
};
