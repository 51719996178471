import SCreateButton from '../SButton/SCreateButton/SCreateButton.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'STags',
	components: {
		SCreateButton
	},
	props: {
		tagsList: {
			type: Array as PropType<string[]>,
			required: true
		},
		modelValue: {
			type: Array as PropType<string[]>,
			default: undefined
		},
		label: {
			type: String,
			default: undefined
		},
		smallLabel: {
			type: Boolean,
			default: false
		},
		showCreateTagButton: {
			type: Boolean,
			default: false
		}
	},
	data(): IInitialState {
		return {
			showCreateTagInput: false,
			isInvalidNewTagName: false,
			concatenatedTagsList: []
		};
	},
	computed: {
		isSelectable() {
			return this.modelValue !== undefined;
		}
	},
	created() {
		this.concatTags(this.tagsList, this.modelValue);
	},
	methods: {
		selectTag(tag: string) {
			if (!this.modelValue || this.isSelectedTag(tag)) {
				return false;
			}

			this.$emit('update:modelValue', [...this.modelValue, tag]);
		},
		removeTag(tag: string) {
			if (!this.modelValue || !this.isSelectedTag(tag)) {
				return false;
			}

			const tagIndex = this.modelValue.indexOf(tag);
			const newValuesList = [...this.modelValue];

			newValuesList.splice(tagIndex, 1);

			this.$emit('update:modelValue', newValuesList);
		},
		createTag(e: Event) {
			const target = e.target as HTMLInputElement | null;
			const createdTag = target?.value;
			const isUniqueTag = Boolean(createdTag && this.tagsList.indexOf(createdTag) === -1);

			if (!target ||
				!createdTag ||
				!isUniqueTag ||
				!this.modelValue ||
				this.isSelectedTag(createdTag)) {
				this.isInvalidNewTagName = true;

				return false;
			}

			const newTagsList = [...this.tagsList, createdTag];

			this.$emit('update:tagsList', newTagsList);
			this.$emit('update:modelValue', [...this.modelValue, createdTag]);

			this.concatTags(newTagsList, this.modelValue);

			target.value = '';
		},
		onCreateButtonClick() {
			this.showCreateTagInput = !this.showCreateTagInput;
		},
		isSelectedTag(tag: string) {
			return Boolean(this.modelValue && this.modelValue.indexOf(tag) !== -1);
		},
		onBlurCreateTagInput() {
			this.showCreateTagInput = false;
		},
		onInputCreateTagInput() {
			this.isInvalidNewTagName = false;
		},
		getTagTitle(tag: string) {
			return this.isClickable(tag) ? 'Select tag' : '';
		},
		isLink(tag: string) {
			return this.isSelectable && this.isSelectedTag(tag);
		},
		isClickable(tag: string) {
			return this.isSelectable && !this.isSelectedTag(tag);
		},
		concatTags(tagsList: string[], surveyTagsList: string[] = []) {
			const concatenatedTagsList = [...tagsList, ...surveyTagsList];
			const uniqueTagsList = concatenatedTagsList.filter((tag, i, arr) => arr.indexOf(tag) === i);

			this.concatenatedTagsList = { ...uniqueTagsList };
		}
	}
});

interface IInitialState {
	showCreateTagInput: boolean;
	isInvalidNewTagName: boolean;
	concatenatedTagsList: string[];
}
