import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SSwitch',
	inheritAttrs: false,
	props: {
		id: {
			type: [String, Number],
			required: true
		},
		label: {
			type: String,
			required: true
		},
		modelValue: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onChange(event: Event) {
			this.$emit('update:modelValue', (event.target as HTMLInputElement).checked);
		}
	}
});
