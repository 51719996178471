import { defineComponent, ref } from 'vue';

export default defineComponent({
	name: 'CHeader',
	props: {
		isAuthorized: {
			type: Boolean,
			default: false
		},
		homeRouteName: {
			type: String,
			required: true
		},
		surveysRouteName: {
			type: String,
			required: true
		},
		onSignUpButtonClick: {
			type: Function,
			required: true
		},
		onLogInButtonClick: {
			type: Function,
			required: true
		},
		onLogOutButtonClick: {
			type: Function,
			required: true
		}
	},
	setup() {
		const navbar = ref<HTMLElement>();
		const navbarBurger = ref<HTMLDivElement>();

		return {
			navbar,
			navbarBurger
		};
	},
	data() {
		return {
			showMobileMenu: false
		};
	},
	mounted() {
		if (this.navbarBurger) {
			this.navbarBurger.onclick = () => {
				this.showMobileMenu = !this.showMobileMenu;
			};

			document.addEventListener('click', this.clickOutsideEvent);
		}
	},
	unmounted() {
		document.removeEventListener('click', this.clickOutsideEvent);
	},
	methods: {
		clickOutsideEvent(e: MouseEvent) {
			const { target } = e;

			if (!target || !this.navbar) {
				return;
			}

			if (!(this.navbar === target || this.navbar.contains(target as Node))) {
				this.showMobileMenu = false;
			}
		}
	}
});
