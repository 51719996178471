import { apiClient } from '../config';
import type { ITags } from './response.types';

class TagsService {
	private readonly _requestPath: string;

	public constructor() {
		this._requestPath = '/tags';
	}

	public getTags() {
		return apiClient.get<ITags[]>(this._requestPath);
	}
}

export const TagsApi = new TagsService();
