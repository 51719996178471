import SCreateButton from '@/shared/SButton/SCreateButton/SCreateButton.vue';
import SNotification from '@/shared/SNotification/SNotification.vue';
import SCodeEditor from '@/shared/SCodeEditor/SCodeEditor.vue';
import SListItem from '@/shared/SListItem/SListItem.vue';
import STextarea from '@/shared/STextarea/STextarea.vue';
import SSpinner from '@/shared/SSpinner/SSpinner.vue';
import SSwitch from '@/shared/SSwitch/SSwitch.vue';
import SButton from '@/shared/SButton/SButton.vue';
import SField from '@/shared/SField/SField.vue';
import SModal from '@/shared/SModal/SModal.vue';
import STabs from '@/shared/STabs/STabs.vue';
import STags from '@/shared/STags/STags.vue';
import type { App } from 'vue';

export const setGlobalSharedComponents = (app: App<Element>) => {
	app
		.component('SCreateButton', SCreateButton)
		.component('SNotification', SNotification)
		.component('SCodeEditor', SCodeEditor)
		.component('SListItem', SListItem)
		.component('STextarea', STextarea)
		.component('SSpinner', SSpinner)
		.component('SSwitch', SSwitch)
		.component('SButton', SButton)
		.component('SModal', SModal)
		.component('SField', SField)
		.component('STabs', STabs)
		.component('STags', STags);
};
