import { TagsApi } from '@/api';
import { ETagsActionType } from './action.type';
import { ETagsMutationType } from './mutation.type';
import type { IStore } from '@/store/index';
import type { ITags } from '@/api';
import type { Module } from 'vuex';

const initialState: IInitialState = {
	tags: null
};

export const tagsStore: Module<IInitialState, IStore> = {
	state: initialState,
	mutations: {
		[ETagsMutationType.SET_TAGS](state, tags: IInitialState['tags']) {
			state.tags = tags;
		}
	},
	actions: {
		async [ETagsActionType.GET_TAGS]({ commit }) {
			await TagsApi.getTags().then(e => {
				commit(ETagsMutationType.SET_TAGS, e.data);
			});
		}
	}
};

export interface IInitialState {
	tags: ITags | null;
}
