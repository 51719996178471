import CHeader from '@/components/CHeader/CHeader.vue';
import CFooter from '@/components/CFooter/CFooter.vue';
import { EUserActionType } from './store';
import { ERouteNames } from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'App',
	components: {
		CHeader,
		CFooter
	},
	data() {
		return {
			homeRouteName: ERouteNames.Home,
			surveysRouteName: ERouteNames.Surveys
		};
	},
	computed: {
		isAuthorized() {
			return Boolean(this.$store.getters.isAuthorized);
		}
	},
	methods: {
		onSignUpButtonClick() {
			this.$router.push({ name: ERouteNames.SignUp });
		},
		onLogInButtonClick() {
			this.$router.push({ name: ERouteNames.Login });
		},
		onLogOutButtonClick() {
			this.$store.dispatch(EUserActionType.LOGOUT);
			this.$router.push({ name: ERouteNames.Home });
		}
	}
});
