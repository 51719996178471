import { LocalStorage } from './localStorage';

class AccessTokenStorage extends LocalStorage {
	public constructor() {
		super({
			storageKey: 'access_token'
		});
	}
}

export const accessTokenStorage = new AccessTokenStorage();
