import SButton from '../SButton/SButton.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'STabs',
	components: {
		SButton
	},
	props: {
		tabsList: {
			type: Array as PropType<string[]>,
			required: true
		},
		modelValue: {
			type: String,
			required: true
		},
		label: {
			type: String,
			default: undefined
		},
		validateTab: {
			type: Function as PropType<(tabName: string) => boolean>,
			default: undefined
		}
	},
	methods: {
		onSelectTab(tabName: string) {
			const canUpdateModelValue = this.validateTab?.(tabName) ?? true;

			if (canUpdateModelValue) {
				this.$emit('update:modelValue', tabName);
			}
		}
	}
});
