import { userStore, surveysStore, tagsStore } from './modules';
import { createStore } from 'vuex';
import type {
	IUserInitialState,
	ISurveysInitialState,
	ITagsInitialState
} from './modules';

export const store = createStore({
	modules: {
		userStore,
		surveysStore,
		tagsStore
	},
	getters: {
		isAuthorized(state) {
			return state.userStore.accessToken;
		}
	}
});

export interface IStore {
	userStore: IUserInitialState;
	surveysStore: ISurveysInitialState;
	tagsStore: ITagsInitialState;
}
