import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = { class: "tabs is-boxed is-small" }
const _hoisted_4 = { class: "ml-0" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsList, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tab,
            class: _normalizeClass({
						'is-active': tab === _ctx.modelValue
					}),
            onClick: ($event: any) => (_ctx.onSelectTab(tab))
          }, [
            _createElementVNode("a", null, _toDisplayString(tab), 1)
          ], 10, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}