import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SButton',
	props: {
		isProcessing: {
			type: Boolean,
			default: false
		},
		sm: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: undefined
		},
		buttonText: {
			type: String,
			default: undefined
		},
		iconClass: {
			type: String,
			default: undefined
		}
	}
});
