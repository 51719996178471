import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = { class: "control has-icons-left has-icons-right" }
const _hoisted_4 = ["id", "placeholder", "value", "name"]
const _hoisted_5 = {
  key: 0,
  class: "icon is-small is-left",
  role: "presentation",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", _mergeProps({
        id: _ctx.name,
        placeholder: _ctx.$attrs.placeholder || _ctx.label,
        value: _ctx.modelValue,
        class: [{
					'pl-2': !_ctx.leftIconClassName
				}, "input"],
        name: _ctx.name
      }, {
					..._ctx.$attrs,
					onInput: _ctx.onInput
				}), null, 16, _hoisted_4),
      (_ctx.leftIconClassName)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.leftIconClassName)
            }, null, 2)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}