import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f771049"),n=n(),_popScopeId(),n)
const _hoisted_1 = { role: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block notification has-text-centered p-4", {
			'notification_centered': _ctx.centered,
			'is-warning is-light': _ctx.warning,
			'is-danger is-light': _ctx.danger
		}])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
    (_ctx.onButtonClick)
      ? (_openBlock(), _createBlock(_component_SButton, {
          key: 0,
          "button-text": _ctx.buttonText,
          sm: "",
          onClick: _ctx.onButtonClick
        }, null, 8, ["button-text", "onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}