import axios from 'axios';

export const apiClient = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json'
	}
});

apiClient.interceptors.response.use(
	response => response,
	error => {
		if (!error.response) {
			// eslint-disable-next-line prefer-promise-reject-errors
			return Promise.reject({
				response: {
					data: 'Error: Network Error'
				}
			});
		}

		return Promise.reject(error);
	}
);
