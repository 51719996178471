import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fc5e0b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = ["id", "name", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      id: _ctx.id,
      type: "checkbox",
      name: _ctx.id,
      class: "switch is-small is-link is-unselectable"
    }, {
				..._ctx.$attrs,
				onChange: _ctx.onChange
			}, { checked: _ctx.modelValue }), null, 16, _hoisted_2),
    _createElementVNode("label", {
      class: "label is-clickable is-unselectable small-text",
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 9, _hoisted_3)
  ]))
}