import { defineComponent } from 'vue';
import { PrismEditor } from 'vue-prism-editor';
import hljs from 'highlight.js';

export default defineComponent({
	name: 'SCodeEditor',
	components: {
		PrismEditor
	},
	props: {
		language: {
			type: String,
			default: 'javascript'
		}
	},
	methods: {
		highlighter(code: string) {
			return hljs.highlight(code, {
				language: this.language,
				ignoreIllegals: true
			}).value;
		}
	}
});
