import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "root-container container has-background-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CHeader = _resolveComponent("CHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CFooter = _resolveComponent("CFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CHeader, {
      "is-authorized": _ctx.isAuthorized,
      "home-route-name": _ctx.homeRouteName,
      "surveys-route-name": _ctx.surveysRouteName,
      "on-sign-up-button-click": _ctx.onSignUpButtonClick,
      "on-log-in-button-click": _ctx.onLogInButtonClick,
      "on-log-out-button-click": _ctx.onLogOutButtonClick
    }, null, 8, ["is-authorized", "home-route-name", "surveys-route-name", "on-sign-up-button-click", "on-log-in-button-click", "on-log-out-button-click"]),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_CFooter)
  ], 64))
}