import { SurveysApi } from '@/api';
import { ESurveysActionType } from './action.type';
import { ESurveysMutationType } from './mutation.type';
import type { IStore } from '@/store/index';
import type { Module } from 'vuex';
import type {
	ICreateSurveyRequest,
	IGetSurveyRequest,
	IEditSurveyRequest,
	ISurvey
} from '@/api';

const initialState: IInitialState = {
	surveysList: null,
	survey: null
};

export const surveysStore: Module<IInitialState, IStore> = {
	state: initialState,
	mutations: {
		[ESurveysMutationType.SET_SURVEYS_LIST](state, surveysList: IInitialState['surveysList']) {
			state.surveysList = surveysList;
		},
		[ESurveysMutationType.SET_SURVEY](state, survey: IInitialState['survey']) {
			state.survey = survey;
		}
	},
	actions: {
		async [ESurveysActionType.GET_SURVEYS_LIST]({ commit }) {
			await SurveysApi.getSurveysList().then(e => {
				commit(ESurveysMutationType.SET_SURVEYS_LIST, e.data);
			});
		},
		async [ESurveysActionType.CREATE_SURVEY](context, data: ICreateSurveyRequest) {
			await SurveysApi.createSurvey(data);
		},
		async [ESurveysActionType.GET_SURVEY]({ commit }, data: IGetSurveyRequest) {
			await SurveysApi.getSurvey(data).then(e => {
				commit(ESurveysMutationType.SET_SURVEY, e.data);
			});
		},
		async [ESurveysActionType.EDIT_SURVEY](context, data: IEditSurveyRequest) {
			await SurveysApi.editSurvey(data);
		}
	}
};

export interface IInitialState {
	surveysList: ISurvey[] | null;
	survey: ISurvey | null;
}
