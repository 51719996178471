import { EUserActionType } from './action.type';
import { EUserMutationType } from './mutation.type';
import { accessTokenStorage } from '../../localStorage';
import { auth } from './actions';
import type { ISignUpRequest, ILoginRequest } from '@/api';
import type { IStore } from '@/store/index';
import type { Module } from 'vuex';

const initialState: IInitialState = {
	user: null,
	accessToken: accessTokenStorage.getItem()
};

export const userStore: Module<IInitialState, IStore> = {
	state: initialState,
	mutations: {
		[EUserMutationType.SET_USER](state, user: IInitialState['user']) {
			state.user = user;
		},
		[EUserMutationType.SET_ACCESS_TOKEN](state, accessToken: IInitialState['accessToken']) {
			state.accessToken = accessToken;
		}
	},
	actions: {
		auth,
		async [EUserActionType.SIGN_UP](context, data: ISignUpRequest) {
			const signUp = auth.handler.bind(this);

			await signUp(context, { ...data, actionType: EUserActionType.SIGN_UP });
		},
		async [EUserActionType.LOGIN](context, data: ILoginRequest) {
			const login = auth.handler.bind(this);

			await login(context, { ...data, actionType: EUserActionType.LOGIN });
		},
		[EUserActionType.LOGOUT]({ commit }) {
			commit(EUserMutationType.SET_ACCESS_TOKEN, null);

			accessTokenStorage.removeItem();
		}
	}
};

export interface IInitialState {
	user: IUser | null;
	accessToken: string | null;
}

interface IUser {
	email: string;
}
