import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-493b6a36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = { class: "control has-icons-left has-icons-right" }
const _hoisted_4 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("textarea", _mergeProps({
        class: ["textarea", {
					'resizable': _ctx.resizable
				}],
        placeholder: _ctx.$attrs.placeholder || _ctx.label,
        value: _ctx.modelValue
      }, {
					..._ctx.$attrs,
					onInput: _ctx.onInput
				}), null, 16, _hoisted_4)
    ])
  ]))
}