import { defineComponent } from 'vue';
import SButton from '../SButton/SButton.vue';

export default defineComponent({
	name: 'SListItem',
	components: {
		SButton
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			default: undefined
		},
		controlButtons: {
			type: Array,
			default: undefined
		}
	},
	computed: {
		abbreviatedDescription() {
			const maxDescriptionSymbols = 147;

			if (this.description && this.description.length > maxDescriptionSymbols) {
				return `${this.description.slice(0, maxDescriptionSymbols)}...`;
			}

			return this.description;
		}
	}
});
